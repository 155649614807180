// para ver todos los adjuntos, ver attachs.js en carpeta services
const requiredAttachsByOoss = {
  // ACA SALUD
  36: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // SANCOR
  66: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // COMEI
  22: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'historia_clinica'], // historia_clinica no siempre lleva TODO ver q hacer aca
  // OSAPM
  41: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // OSAP
  60: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // SWISS MEDICAL S.A.	
  57: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // STAFF MEDICO
  67: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // AMFFA
  26: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // AMEBPBA
  49: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'bono'], // TODO ver q onda el tema de la cantidad de bonos
  // AMSTERDAM SALUD
  80: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // APRES S.A
  89: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'factura'], // TODO agregar factura en front
  // APSOT
  68: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // MOTOCICLISTAS
  33: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'factura'],
  // CASA
  47: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'historia_clinica'], // historia_clinica no siempre lleva TODO ver q hacer aca
  // CASA PLAN BAYRES ??
  // undefined
  // COLEGIO DE ESCRIBANOS
  39: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // DASMI
  84: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // DASUTEN
  69: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'factura'], // TODO agregar factura en front
  // E. W. HOPE
  25: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // ENSALUD
  43: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'factura'], // TODO agregar factura en front
  // FEDERADA SALUD
  74: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // FUTBOLISTAS
  31: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // GALENO
  48: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // GALENODAN
  50: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // IOSFA
  61: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'bono'], // TODO ver q onda el tema de la cantidad de bonos
  // JERARQUICOS
  38: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // LA HOLANDO ART
  34: ['frente_orden', 'dorso_orden', 'planilla_limitacion'], // quitar planilla_asistencia para esta ooss
  // LA SEGUNDA ART
  71: ['frente_orden', 'dorso_orden', 'planilla_limitacion'], // quitar planilla_asistencia para esta ooss
  // LA SEGUNDA PERSONAS
  72: ['frente_orden', 'dorso_orden', 'planilla_limitacion', 'factura'], // quitar planilla_asistencia para esta ooss, TODO agregar factura en front
  // Lpf Grupo La Pequeña Familia
  45: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'bono', 'factura'], // TODO ver q onda el tema de la cantidad de bonos, agregar factura en front
  // LUIS PASTEUR
  30: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // MANDAR SALUD S.A.
  13: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // MEDICAR WORK
  24: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'factura'], // TODO agregar factura en front
  // MEDICUS SA
  53: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // MEDIFE SA
  55: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // OPDEA
  86: ['frente_orden', 'dorso_orden', 'planilla_asistencia'], // TODO IMPORTANTE esta es la que tiene 10 bonos o 10 sesiones, manejar
  // OSDOP
  65: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // OSECAC
  51: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // OSFATLYF
  87: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'bono'], // TODO ver q onda el tema de la cantidad de bonos
  // OSPE
  77: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // OSPEDYC
  81: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // OSPEPBA
  82: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // OSPIA
  73: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'bono'], // TODO ver q onda el tema de la cantidad de bonos
  // OSPICAL
  64: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // OSPSA
  70: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'bono'], // TODO ver q onda el tema de la cantidad de bonos
  // OSPTV
  75: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // OSSEG
  35: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // PODER JUDICIAL
  27: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // PREVENCION SALUD S.A
  85: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'factura'], // TODO agregar factura en front
  // RECONQUISTA ART S.A
  32: ['frente_orden', 'dorso_orden', 'planilla_limitacion', 'factura'], // quitar planilla_asistencia para esta ooss, TODO agregar factura en front
  // SANPEDRO
  54: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // SCIS S-A
  42: ['frente_orden', 'dorso_orden', 'planilla_asistencia'],
  // SMG ART
  83: ['frente_orden', 'dorso_orden', 'planilla_limitacion', 'factura'], // quitar planilla_asistencia para esta ooss, TODO agregar factura en front
  // UP
  46: ['frente_orden', 'dorso_orden', 'planilla_asistencia', 'bono'], // TODO ver q onda el tema de la cantidad de bonos
}

export const getRequiredAttachs = (id) => {
  return requiredAttachsByOoss[parseInt(id)] || ['frente_orden', 'dorso_orden'] // frente_orden required in every OOSS
}

export const getAvailableAttachs = (id) => {
  //
}