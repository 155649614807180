import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from "react"
import ReactDOM from "react-dom"

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import 'assets/sass/app.sass'

import AppRoutes from 'router'

// console.log(process.env)

ReactDOM.render(
  <AppRoutes />,
  document.getElementById("root")
)
