import React, { useEffect, useContext, useState } from "react"
import { useForm } from 'hooks'
// import ApiService from 'services/ApiService'

// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  // Row,
  Col
} from "reactstrap"

import { useHistory } from 'react-router-dom'

import { ApiService } from 'services'
import { UserContext } from "context"
import { useAlert } from "hooks"
import AlertModal from "components/AlertModal"

const initialState = {
  currentPassword: '',
  newPassword: '',
  rePassword: ''
}

function Password () {
  const history = useHistory()

  const [values, handleChange] = useForm(initialState)
  const { value } = useContext(UserContext)
  const [id, setId] = useState(null)
  const [showAlert, message, alert] = useAlert(false, '')
  const { currentPassword, newPassword, rePassword } = values

  useEffect(() => {
    if (value) {
      const { user } = value
      setId(user.id)
    }
  }, [value])
  
  const submit = async () => {
    if (newPassword !== rePassword) {
      return console.log('las contraseñas no coinciden')
    }
    if (!id) {
      return console.log('no se pudo obtener su usuario')
    }
    const data = { action: 'changePassword' }
    Object.assign(data, { currentPassword, newPassword })
    
    await ApiService.update('users', id, data)
    
    // Redirigimos al login e indicamos que se recuperó correctamente la contraseña
    // para que muestre mensaje
    return history.push({
      pathname: '/auth/login',
      state: {
        didChange: true
      }
    })
  }

  return (
    <>
      <AlertModal
        isOpen={showAlert}
        message={message}
        onClose={() => alert(false)}
      />
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-5">
              <h3>Cambiar contraseña</h3>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password actual"
                    name='currentPassword'
                    type="password"
                    value={currentPassword}
                    onChange={ handleChange }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Nueva password"
                    name='newPassword'
                    type="password"
                    value={newPassword}
                    onChange={ handleChange }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Repita nueva password"
                    name='rePassword'
                    type="password"
                    value={rePassword}
                    onChange={ handleChange }
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={ submit }
                >
                  Cambiar contraseña
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default Password
