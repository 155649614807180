import React, { useContext, useEffect } from "react"
import { useForm } from 'hooks'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap"

import { ApiService } from 'services'
import { UserContext } from "context"
import { useHistory, Link, useLocation } from "react-router-dom"
import { useAlert } from "hooks"
import AlertModal from "components/AlertModal"

const initialState = {
  email: '',
  password: ''
}

function Login () {

  const history = useHistory()
  const location = useLocation()
  const [showAlert, message, alert] = useAlert(false, '')
  const [values, handleChange] = useForm(initialState)
  const { setValue } = useContext(UserContext)
  const { email, password } = values

  useEffect(() => {
    history.replace({
      pathname: '/auth/login'
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.state) {
      const { didRecover, didChange } = location.state
      if (didRecover) {
        alert(true, 'La contraseña se ha restablecido. Ya puede iniciar sesión.')
        history.replace({
          pathname: '/auth/login',
          state: {}
        })
      } else if (didChange) {
        alert(true, 'La contraseña se cambio correctamente. Por favor, vuelva a iniciar sesión.')
        history.replace({
          pathname: '/auth/login',
          state: {}
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      return submit()
    }
  }
  
  const submit = async () => {
    try {
      const res = await ApiService.signIn(values)

      if (res.status === 401) {
        alert(true, 'Las credenciales ingresadas son inválidas. Por favor, verifique el email y contraseña e intente nuevamente')
        return
      }
  
      if (res.status !== 200) {
        alert(true, 'Se produjo un error al intentar validar las credenciales ingresadas. Por favor, intente nuevamente más tarde')
        return
      }
  
      const data = await res.json()
      console.log(data)
      setValue(data)
      const { accessToken } = data
      // store the tokens in the browser
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('userData', JSON.stringify(data))
      history.push('/admin/presentations')
    } catch(err) {
      alert(true, 'Se produjo un error al intentar validar las credenciales ingresadas. Por favor, intente nuevamente más tarde')
      return
    }
  }

  return (
    <>
      <AlertModal
        isOpen={showAlert}
        message={message}
        onClose={() => alert(false)}
      />
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-5">
              <h3>Iniciar sesión</h3>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    name='email'
                    type="email"
                    value={email}
                    autoComplete="new-email"
                    onKeyDown={ onKeyDown }
                    onChange={ handleChange }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    name='password'
                    type="password"
                    value={password}
                    autoComplete="new-password"
                    onKeyDown={ onKeyDown }
                    onChange={ handleChange }
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Recordarme</span>
                </label>
              </div>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={ submit }
                >
                  Iniciar sesión
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link
              className="text-light"
              to="/auth/recover-password"
            >
              <small>Olvidaste tu contraseña?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default Login
