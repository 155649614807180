import React, { useState } from "react"
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Col } from "reactstrap"

import { ApiService } from 'services'
import { useHistory } from 'react-router-dom'
import AlertModal from "components/AlertModal"
import { useAlert } from "hooks"

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
const mailPattern =  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

function RecoverPassword () {
  const [ step, setStep ] = useState(1)
  const [ loading, setLoading ] = useState(false)

  const history = useHistory()

  const [ email, setEmail ] = useState('')
  const [ code, setCode ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ passwordConfirmation, setPasswordConfirmation ] = useState('')

  const [ showAlert, message, alert ] = useAlert(false, '')

  const submitEmail = async () => {
    if (!email) {
      alert(true, 'Tiene que ingresar el email para poder recuperar la contraseña')
      return
    }

    if (!mailPattern.test(email)) {
      alert(true, 'El email ingresado no es válido')
      return
    }

    setLoading(true)

    // Iniciamos el proceso de recuperación
    const res = await ApiService.postAuth('recover', { email })

    setLoading(false)

    // Si no hay respuesta o no incluye status probablemente hubo un problema de
    // conexión así que devolvemos un error genérico
    if (!res || !res.status) {
      alert(true, 'Se produjo un error cuando se intentó enviar un correo electrónico para iniciar el proceso de recuperación de contraseña. Por favor, intente nuevamente más tarde')
      return
    }

    // Si el webservice indicó que se produjo un error, mostramos el mensaje correspondiente
    if (res.status === 'error') {
      // Usuario no encontrado
      if (res.code === 'NOT_FOUND') {
        alert(true, 'El email ingresado no se encuentra en nuestros registros. Por favor, verifique si es correcto')
        return
      }

      // Errores desconocidos
      alert(true, 'Se produjo un error cuando se intentó iniciar el proceso de recuperación de contraseña. Por favor, intente nuevamente más tarde')
      return
    }

    // Informamos que se envió el código
    alert(true, 'Le hemos enviado un código de recuperación por correo electrónico.')

    // Cambiamos de paso del proceso
    setStep(2)
  }

  const submitCode = async () => {
    if (!code) {
      alert(true, 'Tiene que ingresar el código para poder recuperar la contraseña')
      return
    }

    if(Number.isNaN(code)) {
      alert(true, 'El código para poder recuperar la contraseña es un número de 5 dígitos que se envió a su correo electrónico')
      return
    }

    setLoading(true)

    // Enviamos el código a validar
    const res = await ApiService.postAuth('code', { email, code })

    setLoading(false)

    // Si no hay respuesta o no incluye status probablemente hubo un problema de
    // conexión así que devolvemos un error genérico
    if (!res || !res.status) {
      alert(true, 'Se produjo un error cuando se intentó validar el código ingresado. Por favor, intente nuevamente más tarde')
      return
    }

    // Si el webservice indicó que se produjo un error, mostramos el mensaje correspondiente
    if (res.status === 'error') {
      // Usuario no encontrado
      if (res.code === 'INVALID_CODE') {
        alert(true, 'El código ingresado no es válido. Por favor, verifique si es correcto o inicie nuevamente el proceso de recuperación')
        return
      }

      // Errores desconocidos
      alert(true, 'Se produjo un error cuando se intentó iniciar el proceso de recuperación de contraseña. Por favor, intente nuevamente más tarde')
      return
    }

    // Cambiamos de paso del proceso
    setStep(3)
  }

  const submitPassword = async () => {
    if (!password || !passwordConfirmation) {
      alert(true, 'Tiene que ingresar y confirmar la nueva contraseña para realizar el cambio')
      return
    }

    if (password !== passwordConfirmation) {
      alert(true, 'Las contraseñas ingresadas tienen que ser iguales')
      return
    }

    setLoading(true)

    // Enviamos el nuevo password para realizar el cambio
    const res = await ApiService.postAuth('change-password', { email, code, password })

    setLoading(false)

    // Si no hay respuesta, no incluye status o se produjo un error mostramos un mensaje genérico
    if (!res || !res.status || res.status === 'error') {
      alert(true, 'Se produjo un error cuando se intentó cambiar la contraseña. Por favor, intente nuevamente más tarde')
      return
    }

    // Redirigimos al login e indicamos que se recuperó correctamente la contraseña
    // para que muestre mensaje
    return history.push({
      pathname: '/auth/login',
      state: {
        didRecover: true
      }
    })
  }

  return (
    <>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ () => alert(false) } />
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-5">
              <h3>Recuperar contraseña</h3>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                {
                  step === 1
                  ? <>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Ingrese su email"
                          name="email"
                          type="email"
                          value={ email }
                          onChange={ event => setEmail(event.target.value) }
                          disabled={ loading }
                        />
                      </InputGroup>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={ () => submitEmail() }
                          disabled={ loading }
                        >
                          Recuperar
                        </Button>
                        <Button
                          className="my-4"
                          color="secondary"
                          type="button"
                          onClick={ () => history.goBack() }
                          disabled={ loading }
                        >
                          Cancelar
                        </Button>
                      </div>
                    </>
                  : null
                }
                {
                  step === 2
                  ? <>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="off"
                          placeholder="Ingrese el código de 5 dígitos"
                          name="code"
                          type="number"
                          value={ code }
                          onChange={ event => setCode(event.target.value) }
                          disabled={ loading }
                        />
                      </InputGroup>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={ submitCode }
                        >
                          Confirmar
                        </Button>
                      </div>
                    </>
                  : null
                }
                {
                  step === 3
                  ? <>
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Nueva Contraseña"
                            name="password"
                            type="password"
                            value={ password }
                            onChange={ event => setPassword(event.target.value) }
                            disabled={ loading }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Repita la Nueva Contraseña"
                            name="passwordConfirmation"
                            type="password"
                            value={ passwordConfirmation }
                            onChange={ event => setPasswordConfirmation(event.target.value) }
                            disabled={ loading }
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={ submitPassword }
                        >
                          Cambiar contraseña
                        </Button>
                      </div>
                    </>
                  : null
                }
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default RecoverPassword
