import { useState } from 'react'

// user management hook
export const useAuth = (initialValues) => {
  const [values, setValues] = useState(initialValues)

  const getCurrentUser = () => {
    if (!values) { return null }
    return values.user
  }

  return [
    values,
    setValues,
    getCurrentUser
  ]
}