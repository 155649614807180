import React, { useEffect, useState } from "react"
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom"

import { Container, Row, Col } from "reactstrap"

// core components
import AuthNavbar from "components/Navbars/AuthNavbar"
import AuthFooter from "components/Footers/AuthFooter"

import routes from "router/routes"

function Auth (props) {

  const history = useHistory()
  const location = useLocation()
  const [from, setFrom] = useState('')
  const [pathname, setPathname] = useState('')

  useEffect(() => {
    // mounted
    setPathname(props.location.pathname)
    document.body.classList.add("bg-default")
    return () => {
      // unmounted
      document.body.classList.remove("bg-default")
    }
  }, [props])

  useEffect(() => {
    if (location.state) {
      const { from } = location.state
      setFrom(from)
    }
  }, [location])

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  return (
    <>
      <div className="main-content">
        <AuthNavbar />
        <div className="header bg-gradient-info py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">
                    {
                      pathname === '/auth/change-password'
                      ? 'Cambio de contraseña'
                      : 'Bienvenido a COKIBA EXTRANET!'
                    }
                  </h1>
                  { pathname === '/auth/change-password' &&
                    from !== 'recoverPass' &&
                    <h2>
                      <span className='clickable' onClick={ () => history.goBack() }>
                        <i className='fa fa-arrow-left'></i> Volver
                      </span>
                    </h2>
                  }
                  {/* <img
                    className='img-logo__auth'
                    alt="..."
                    src={require("assets/img/brand/logo.png")}
                  /> */}
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              { getRoutes(routes) }
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  )
}

export default Auth
