import Profile from "views/Profile.js"
import Login from "views/Login.js"
import Password from "views/Password"
import RecoverPass from "views/RecoverPassword"

import ListPresentation from 'views/Presentation/List'
import ShowPresentation from 'views/Presentation/Show'

import ShowBenefits from 'views/Benefits/Show'

const routes = [
  // Usuario Perfil
  {
    path: "/profile",
    name: "Editar perfil",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    show: false
  },

  // Prestaciones
  {
    path: "/benefits/:id",
    component: ShowBenefits,
    layout: "/admin",
    show: false
  },

  // Presentaciones
  {
    path: "/presentations",
    name: "Gestion de Presentaciones",
    icon: "ni ni-collection text-blue",
    component: ListPresentation,
    layout: "/admin",
    show: true
  },
  {
    path: "/presentations/:id",
    component: ShowPresentation,
    layout: "/admin",
    show: false
  },
  // LOGIN - CAMBIO PASS
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    show: false
  },
  {
    path: "/change-password",
    name: "Cambiar contraseña",
    icon: "ni ni-key-25 text-info",
    component: Password,
    layout: "/auth",
    show: false
  },
  {
    path: "/recover-password",
    name: "Recuperar contraseña",
    component: RecoverPass,
    layout: "/auth",
    show: false
  },
]
export default routes
