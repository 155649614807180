import React from 'react'

// reactstrap components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"

export default function (props) {

  const {
    data,
    onClose
  } = props

  const close = () => {
    if (onClose) {
      return onClose()
    }
  }

  return (
    <Modal isOpen={data.show} className='attach-modal modal-dialog-centered'>
      <ModalHeader>
        {
          data.title
        }
      </ModalHeader>
      <ModalBody>
        {
          <a href={ data.url } target="_blank" rel="noopener noreferrer">
            <img src={ data.url || ''} alt={data.title} className='attach-img'></img>
          </a>
        }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ () => close() }>Cerrar</Button>
      </ModalFooter>
    </Modal>
  )
}