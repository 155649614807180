import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
  Row,
  Col,
} from "reactstrap";
import { roles } from 'services'
import { useForm } from "hooks"
import { delegations } from "services";

function UserForm (props) {
  const {
    action,
    title,
    data,
    setData,
    onSubmit,
    fieldClass,
    isValidated,
    setFieldClass
  } = props

  const [values, handleChange] = useForm(data)
  const [matchPassword, setMatchPassword] = useState(undefined)

  useEffect(() => {
    setData(values)
  }, [values, setData])

  useEffect(() => {
    validatePassword(data.password, data.rePassword)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.password, data.rePassword])

  const validatePassword = (password, rePassword) => {
    if (password && rePassword) {
      if (password !== rePassword) {
        setFieldClass({
          ...fieldClass,
          password: 'required',
          rePassword: 'required'
        })
        setMatchPassword(false)
        return false
      } else {
        setFieldClass({
          ...fieldClass,
          password: 'is-valid',
          rePassword: 'is-valid'
        })
        setMatchPassword(true)
        return true
      }
    } else {
      setMatchPassword(undefined)
      setFieldClass({
        ...fieldClass,
        password: '',
        rePassword: ''
      })
    }
  }

  const submit = () => {
    return onSubmit()
  }

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">{ title }</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {
          !isValidated &&
          <UncontrolledAlert color='danger'>
            Por favor, complete los campos vacíos.
          </UncontrolledAlert>
        }
        <Form>
          <h6 className="heading-small text-muted mb-4">
            Información de usuario
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    Nombre
                  </label>
                  <Input
                    autoComplete='off'
                    className={`form-control-alternative ${fieldClass['firstName']}`}
                    type="text"
                    name='firstName'
                    value={values.firstName}
                    placeholder='Nombre'
                    onChange={ handleChange }
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Apellido
                  </label>
                  <Input
                    autoComplete='off'
                    className={`form-control-alternative ${fieldClass['lastName']}`}
                    placeholder="Apellido"
                    type="text"
                    name='lastName'
                    value={values.lastName}
                    onChange={ handleChange }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-email"
                  >
                    Email
                  </label>
                  <Input
                    autoComplete='off'
                    className={`form-control-alternative ${fieldClass['email']}`}
                    id="input-email"
                    name='email'
                    value={values.email}
                    placeholder="example@example.com"
                    type="email"
                    disabled={action === 'profile'}
                    onChange={ handleChange }
                  />
                </FormGroup>
              </Col>
              {
                action !== 'profile' &&
                <>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-pass"
                      >
                        Contraseña
                      </label>
                      <Input
                        className={`form-control-alternative ${fieldClass['password']}`}
                        name='password'
                        value={values.password}
                        placeholder="Contraseña"
                        type="password"
                        disabled={action === 'edit'}
                        onChange={ handleChange }
                      />
                      {
                        matchPassword !== undefined &&
                        <label
                          className={`input-tip input-tip--${matchPassword ? 'valid' : 'invalid'}`}
                        >
                          {`Las contraseñas ${matchPassword ? '' : 'no'} coinciden`}
                        </label>
                      }
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-repeat-pass"
                      >
                        Repetir contraseña
                      </label>
                      <Input
                        className={`form-control-alternative ${fieldClass['rePassword']}`}
                        placeholder="Repetir contraseña"
                        type="password"
                        name='rePassword'
                        value={values.rePassword}
                        disabled={action === 'edit'}
                        onChange={ handleChange }
                      />
                    </FormGroup>
                  </Col>
                </>
              }
            </Row>
            <Row>
              <div className="update ml-auto mr-auto">
                <Button
                  className="btn-round"
                  color="primary"
                  title='Crear usuario'
                  onClick={ () => submit() }
                  disabled={ false }
                >
                  Guardar
                </Button>
              </div>
            </Row>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default UserForm
