import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Card, CardHeader, CardBody, Row, Col, Form, FormGroup, Label, Button, Input, Table } from 'reactstrap'
import { ApiService } from 'services'
import { useAlert } from 'hooks'
import DatePicker from 'components/DatePicker'
import AlertModal from 'components/AlertModal'
import Attachments from 'components/Requests/Attachments'
import config from 'config'
import moment from 'moment'

function Show () {
  const { id } = useParams()

  const history = useHistory()

  const [ files, setFiles ] = useState({})

  const [ formData, setFormData ] = useState({
    insurer_id: null,
    insurer_external_id: null,
    authorization_id: null,
    recipient_id: null,
    recipient_name: null,
    recipient_age: null,
    prescription_date: null,
    diagnosis: null,
    impairment: false,
    sessions: [],
    practice_id: null,
    additional_id: null,
    files: {},
    previously_authorized: null,
    effector_id: null,
    max_sessions: 5
})

  const [ prestaciones, setPrestaciones ] = useState({
    modulosPrestacion: [],
    adicionales: [] 
  })

  const [ showAlert, message, alert ] = useAlert(false, '')
  const [ isLoading, setLoading ] = useState(true)

  useEffect(() => {
    ApiService.getOne('requests', id)
    .then(res => {
      if (!res.id) {
        alert(true, 'Se produjo un error al intentar recuperar la información de la presentación. Por favor, intente nuevamente más tarde')
        return
      }

      const data = formData

      // Parseamos las fechas en objetos Date para el DatePicker
      if (res.fecha_prescripcion_medica) {
        data.prescription_date = new Date(res.fecha_prescripcion_medica)
      }

      // Parseamos las fechas en objetos Date para el DatePicker
      res.sessionDates.forEach((session) => {
        data.sessions.push({
          date: new Date(session.date),
          authorization: session.approvalNumber
        })
      })

      data.previously_authorized = res.aprobacion_previa

      if (res.numero_afiliado) {
        data.recipient_age = res.affiliate.edad
        data.recipient_id = res.affiliate.numero_afiliado
        data.recipient_name = res.affiliate.nombre_apellido
      }

      data.diagnosis = res.diagnostico

      data.impairment = res.prestacion_discapacidad

      data.practice_id = res.idTipoPractica
      data.practice_text = res.tipo_practica
      data.additional_id = res.idPracticaAdicional
      data.additional_text = res.practica_adicional

      data.authorization_id = res.numero_aprobacion

      data.insurer_id = res.ooss_id
      data.insurer_external_id = res.ooss?.numero

      data.max_sessions = res.cantidad_sesiones ? parseInt(res.cantidad_sesiones) : 5

      if (res.attachs && Array.isArray(res.attachs)) {
        const newFiles = {}
        res.attachs.forEach(file => {
          newFiles[file.tipo] = {
            type: file.tipo_archivo === 'pdf' ? 'application/pdf' : 'image/jpeg', // En realidad aparte de application/pdf, el otro tipo no importa
            url: config.baseUrl + '/' + file.url
          }
        })

        setFiles(newFiles)
      }

      // Actualizamos la información del estado
      setFormData(data)
    })
    .catch(err => {
      setLoading(false)
      alert(true, 'Se produjo un error al intentar recuperar la información de la presentación. Por favor, intente nuevamente más tarde')
      console.log(err)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Card className="bg-secondary shadow">
          <CardHeader>
            <Row>
              <Col md="8">
                <h3 className="mb-0">Prestación #{ id }</h3>
              </Col>
              <Col md="4" className="text-right">
                <Button color="primary" size="md" onClick={ () => history.goBack() }>
                  Volver
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <h4>Facturación CoKiBA</h4>
              </FormGroup>
              <FormGroup className={ formData.insurer_id !== 57 ? 'd-block' : 'd-none' }>
                <Label for="authorization_id">Número de Aprobación</Label>
                <Row form>
                  <Col md={6}>
                    <Input
                      id="authorization_id"
                      name="authorization_id"
                      defaultValue={ formData.authorization_id ?? '' }
                      disabled={ true }
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="recipient_id">Número de Afiliado</Label>
                <Input
                  id="recipient_id"
                  name="recipient_id"
                  defaultValue={ formData.recipient_id ?? '' }
                  disabled={ true }
                />
              </FormGroup>
              <FormGroup>
                <Label for="recipient_name">Nombre del Afiliado</Label>
                <Input
                  id="recipient_name"
                  name="recipient_name"
                  defaultValue={ formData.recipient_name ?? '' }
                  disabled={ true }
                />
              </FormGroup>
              <FormGroup>
                <Label for="recipient_age">Edad</Label>
                <Input
                  id="recipient_age"
                  name="recipient_age"
                  defaultValue={ formData.recipient_age ?? '' }
                  disabled={ true }
                />
              </FormGroup>
              <FormGroup>
                <Row form>
                  <Col sm={4}>
                    <Label for="prescription_date">Fecha Prescripción Médica</Label>
                    <DatePicker
                      id="prescription_date"
                      name="prescription_date"
                      selected={ formData.prescription_date ?? '' }
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Fecha Prescripción"
                      className="date-picker form-control"
                      disabled={ true }
                      autoComplete="off"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="diagnosis">Diagnóstico</Label>
                <Input
                  id="diagnosis"
                  name="diagnosis"
                  type="textarea"
                  defaultValue={ formData.diagnosis ?? '' }
                  disabled={ true }
                />
              </FormGroup>
              <FormGroup style={{ paddingLeft: '1.5rem' }}>
                <Input
                  id="impairment"
                  name="impairment"
                  type="checkbox"
                  checked={ formData.impairment }
                  disabled={ true }
                />
                <Label for="impairment" style={{ marginBottom: 0, verticalAlign: 'bottom' }}>
                  Es discapacidad
                </Label>
              </FormGroup>
              <FormGroup className={ formData.previously_authorized ? 'd-block' : 'd-none' }>
                <Row form>
                  <Col md={4}>
                    <Label>Cantidad de Sesiones</Label>
                    <Input
                      id="max_sessions"
                      name="max_sessions"
                      type="number"
                      min="1"
                      disabled={ true }
                      defaultValue={ formData.max_sessions }
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label>Fechas de sesiones</Label>
                <Table className='align-items-center table-flush mb-0 col-sm-6' style={{ fontSize: '.8rem' }} responsive={ false }>
                  <thead>
                    <tr>
                      <th>FECHA SESIÓN</th>
                      {
                        formData.insurer_id === 57 // Swiss
                        ? <th>AUTORIZACIÓN</th>
                        : null
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      formData.sessions && Array.isArray(formData.sessions)
                      ? formData.sessions.map(({ date, authorization }, i) => (
                        <tr key={ i }>
                          <td>{ new Date(date).toLocaleDateString('es') }</td>
                          {
                            formData.insurer_id === 57 // Swiss
                            ? <td>{ authorization ?? ' - ' }</td>
                            : null
                          }
                        </tr>
                      ))
                      : null
                    }
                  </tbody>
                </Table>
              </FormGroup>
              <FormGroup row>
                <Col md="6">
                  <Label for="practice_id">Tipo de práctica</Label>
                  <Input
                    id="practice_id"
                    name="practice_id"
                    type="select"
                    className="custom-select"
                    defaultValue={ formData.practice_id ?? '' }
                    disabled={ true }
                  >
                    <option value={ formData.practice_id }>{ formData.practice_text }</option>
                  </Input>
                </Col>
                <Col md="6">
                  <Label for="additional_id">Práctica adicional</Label>
                  <Input
                    id="additional_id"
                    name="additional_id"
                    type="select"
                    className="custom-select"
                    defaultValue={ formData.additional_id ?? '' }
                    disabled={ true }
                  >
                    <option value=''>Ninguna</option>
                    {
                      formData.additional_id ? <option value={ formData.additional_id }>{ formData.additional_text }</option> : null
                    }
                  </Input>
                </Col>
              </FormGroup>
              <Attachments
                insurer_id={ formData.insurer_id }
                files={ files }
                sessions={ formData.sessions?.length ?? 0 }
                setFiles={ setFiles }
                impairment={ formData.impairment }
              />
            </Form>
            <AlertModal isOpen={ showAlert } message={ message } onClose={ () => alert(false) } />
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default Show
