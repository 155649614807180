import React, { useState, useEffect } from 'react'

import { Row, Col, Button, FormGroup, Label } from 'reactstrap'

import PreviewModal from 'components/PreviewModal'
import { getRequiredAttachs } from 'helpers'
import { attachs as attachsLabels} from 'services/attachs'

export default function (props) {
  const { insurer_id, sessions, files, impairment = false, disabled = false } = props
  const [ attachModal, setAttachModal ] = useState({})
  const [ isLoading, setLoading ] = useState(false)
  const [ required, setRequired ] = useState([])

  const handleFilePreview = (name) => {
    try {
      setLoading(true)

      console.log(name)

      // Si no hay una url definida porque no viene de la BBDD o porque todavía
      // no se previsualizó, creamos una
      if (!files[name].url) {
        files[name].url = URL.createObjectURL(files[name])
      }

      // Los pdfs los abrimos en una nueva pestaña/ventana
      if (files[name].type === 'application/pdf') {
        window.open(files[name].url)
        setLoading(false)
        return
      }

      // Para todo lo demás utilizamos el modal
      setAttachModal({
        show: true,
        url: files[name].url,
        title: attachsLabels[name]
      })
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(disabled)
  }, [disabled])

  useEffect(() => {
    let newRequired = required

    switch(insurer_id) {
      case 86: { // OPDEA
        let bonos = []
        for (let i = 1; i <= sessions; i++) {
          bonos.push(`bono_${ i }`)
        }

        newRequired = [
          ...getRequiredAttachs(insurer_id),
          ...bonos
        ]
        break;
      }
      default:
        newRequired = getRequiredAttachs(insurer_id)
    }

    newRequired = newRequired.filter(r => r !== 'certificado_discapacidad')
    if (impairment) {
      newRequired.push('certificado_discapacidad')
    }

    setRequired(newRequired)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurer_id, sessions, impairment])

  return (
    <>
      <FormGroup className={ props.className  }>
        <Label>Adjuntos</Label>
        <Row>
          {
            required.map(key => (
              <Col sm={ 6 } key={ key } className="mt-2">
                <label>{ attachsLabels[key] }</label>
                <div className={ files[key] ? 'd-block' : 'd-none' }>
                  <Button color="primary" onClick={ () => handleFilePreview(key) } disabled={ isLoading }>
                    Ver adjunto
                  </Button>
                </div>
              </Col>
            ))
          }
        </Row>
      </FormGroup>

      <PreviewModal data={ attachModal } onClose={ () => setAttachModal({ ...attachModal, show: false }) } />
    </>
  )
}