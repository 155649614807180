import React, { useMemo } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import AdminLayout from "layouts/Admin.js"
import AuthLayout from "layouts/Auth.js"

import { UserContext } from 'context'
import { useAuth } from 'hooks'

function AppRoutes () {
  const [value, setValue, getCurrentUser] = useAuth(null)
  const providerValue = useMemo(() => ({ value, setValue, getCurrentUser }), [value, setValue, getCurrentUser])

  if (localStorage.getItem('userData')) {
    try {
      const userData = JSON.parse(localStorage.getItem('userData'))
      setValue(userData)

    } catch(err) {}
  }

  return (
    <Router>
      <UserContext.Provider value={providerValue}>
        <Switch>
          <Route path="/admin" render={props => <AdminLayout {...props} />}></Route>
          <Route path="/auth" render={props => <AuthLayout {...props} />}></Route>
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </UserContext.Provider>
    </Router>
  )
}

export default AppRoutes

export { default as routes }  from './routes'